import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Image } from './types'
import { ContentfulAsset } from './types/contentful'
import imageEmpty from '../images/empty.png'
import imageFallback from '../images/fallback.png'

export type ContentfulTypeCompany = {
  /** UUID format */
  id: string
  /** Copmany name */
  name: string
  /** A short description (long text, plain) */
  shortDescription: {
    shortDescription: string
  }
  /** Company's industry */
  category: string
  /** Header image as needed */
  headerImage: ContentfulAsset
  /** Square logo, hopefully singe-color black on transparent backgroud */
  logo: ContentfulAsset
  /** Link to their site */
  url: string
  /** Relationship with company has ended */
  exited: boolean
}

export type Company = {
  /** UUID format */
  id: string
  /** Copmany name */
  name: string
  /** A short description (long text, plain) */
  description: string
  /** Company's industry */
  category: string
  /** Header image as needed */
  header: Image
  /** Square logo, hopefully singe-color black on transparent backgroud */
  logo: Image
  /** Link to their site */
  slug: string
  /** Show in homepage carousel? */
  exited: boolean
}

export const fragment = graphql`
  fragment CompanyFields on ContentfulTypeCompany {
    id
    name
    url
    category
    exited
    shortDescription {
      shortDescription
    }
    logo {
      id
      description
      file {
        url
      }
    }
    headerImage {
      id
      description
      file {
        url
      }
    }
  }
`

export const transform = (node: ContentfulTypeCompany): Company => ({
  id: node.id,
  name: node.name,
  description: get(node, 'shortDescription.shortDescription', ''),
  category: node.category,
  exited: node.exited,
  slug: node.url,
  header: {
    src: get(node, 'headerImage.file.url', imageFallback),
    alt: get(node, 'headerImage.description', 'None provided'),
  },
  logo: {
    src: get(node, 'logo.file.url', imageEmpty),
    alt: get(node, 'logo.description', 'None provided'),
  },
})
