import * as React from 'react'
import cx from 'classnames'

import { ContentfulBlockCallToAction } from '../../data/block-cta'
import { Button } from '../button'
import { Link } from '../link'
import { useStaticTracking } from '../../hooks'

// @ts-ignore
import styles from './block-cta.module.scss'

interface Props {
  query: ContentfulBlockCallToAction
}

export default function BlockCTA({ query }: Props) {
  const [onTrack] = useStaticTracking({
    action: 'Click button',
    category: 'CTA Block',
    label: query.button.text,
  })
  return (
    <aside
      id={query.anchor}
      className={cx('container container--grid', styles.block, {
        [styles.outline]: query.theme === 'outline',
        [styles.inverse]: query.theme === 'inverse',
      })}
    >
      <div className={cx('container__row', styles.content)}>
        <div className={cx(styles.content__heading, 'child-my-0')}>
          <h2 className="type-h-xl">{query.heading}</h2>
        </div>
        <div className={styles.content__button}>
          <Button
            arrow
            fill
            as={Link}
            to={query.button.location}
            onClick={onTrack}
            appearance="secondary"
          >
            {query.button.text}
          </Button>
        </div>
      </div>
    </aside>
  )
}
