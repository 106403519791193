import * as React from 'react'
import { graphql } from 'gatsby'
import anime from 'animejs'

import { transform as toHomePage, ContentfulPageHome } from '../data/home-page'
import { transform as toCompany, ContentfulTypeCompany } from '../data/company'
import {
  transform as toGlobals,
  ContentfulSettingsDesignerFund,
} from '../data/globals'

import {
  HomeHeader,
  CompanyCarousel,
  CompanyListSection,
  ProgramsCarousel,
  ResourcesSection,
} from '../components/section'
import BlockCTA from '../components/marketing-page/block-cta'

interface IndexPageProps {
  data: {
    globals: {
      edges: { node: ContentfulSettingsDesignerFund }[]
    }
    companies: {
      edges: { node: ContentfulTypeCompany }[]
    }
    page: {
      edges: { node: ContentfulPageHome }[]
    }
  }
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
  onRequestHeaderScroll = () => {
    const el = document.querySelector('#our-companies > div:first-child')
    const { scrollingElement, body, documentElement } = document
    const container = scrollingElement || body || documentElement
    if (el && container) {
      anime({
        duration: 2000,
        easing: 'easeOutExpo',
        scrollTop: el.offsetTop - 48,
        targets: container,
      })
    }
  }

  public render() {
    const { data } = this.props
    const companies = data.companies.edges.map(({ node }) => toCompany(node))
    const [portfolioCompanies, exitedCompanies] = [
      companies.filter(c => !c.exited),
      companies.filter(c => c.exited),
    ]
    const [page] = data.page.edges.map(({ node }) => toHomePage(node))
    const [globals] = data.globals.edges.map(({ node }) => toGlobals(node))

    return (
      <>
        <HomeHeader
          tagline={page.header.subtitle}
          title={page.header.title}
          description={page.header.description}
          images={page.header.images}
          onRequestScrollDown={this.onRequestHeaderScroll}
          button={page.header.button}
        />
        <CompanyCarousel
          id="our-companies"
          title={page.featuredCompanies.title}
          tagline={page.featuredCompanies.subtitle}
          companies={page.featuredCompanies.items}
        />
        <CompanyListSection
          id="all-companies"
          title="All Companies"
          companies={portfolioCompanies}
        />
        {exitedCompanies.length ? (
          <CompanyListSection
            title="Acquired Companies"
            companies={exitedCompanies}
          />
        ) : null}
        {page.ctaSection && (
          <BlockCTA
            query={{
              id: 'homepage-cta',
              anchor: 'homepage-cta-section',
              heading: page.ctaSection.title,
              theme: 'outline',
              button: page.ctaSection.button,
            }}
          />
        )}
        <ProgramsCarousel
          id="programs"
          title="Programs"
          programs={page.programs.items}
          description={page.programs.title}
        />
        <ResourcesSection title="Resources" resources={globals.resources} />
      </>
    )
  }
}

export const query = graphql`
  query IndexPage {
    globals: allContentfulSettingsDesignerFund(limit: 1) {
      edges {
        node {
          ...GlobalsFields
        }
      }
    }
    companies: allContentfulTypeCompany(sort: { fields: [name] }) {
      totalCount
      edges {
        node {
          ...CompanyFields
        }
      }
    }
    page: allContentfulPageHome {
      edges {
        node {
          ...HomePageFields
        }
      }
    }
  }
`
