import { graphql } from 'gatsby'
import get from 'lodash/get'

export type ContentfulTypeLink = {
  /** UUID format */
  id: string
  /** Display name */
  text: string
  /** Destination */
  location: string
}

// There's a widely used Link component
// so this needs to deviate from the
// usual naming convention
export type LinkItem = {
  /** UUID format */
  id: string
  /** Display name */
  text: string
  /** Destination */
  location: string
}

export const fragment = graphql`
  fragment LinkFields on ContentfulTypeLink {
    id
    text
    location
  }
`

export const transform = (node: ContentfulTypeLink): LinkItem => ({
  id: node.id,
  text: get(node, 'text', ''),
  location: get(node, 'location', ''),
})
