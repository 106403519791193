import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Image } from './types'
import { ContentfulAsset } from './types/contentful'
import imageFallback from '../images/fallback.png'

export type ContentfulTypeProgram = {
  /** UUID format */
  id: string
  /** Link to the program (short text) */
  url: string
  /** Program Name (short text) */
  name: string
  /** A short description (long text, plain) */
  description: {
    /** Assumed plain-text */
    description: string
  }
  /** The carousel image */
  image: ContentfulAsset
}

export type Program = {
  /** UUID format */
  id: string
  /** Link to the program (short text) */
  url: null | string
  /** Program Name (short text) */
  name: string
  /** A short description (long text, plain) */
  description: string
  /** The carousel image */
  image: Image
}

export const fragment = graphql`
  fragment ProgramFields on ContentfulTypeProgram {
    id
    url
    name
    description {
      description
    }
    image {
      id
      description
      file {
        url
      }
    }
  }
`

export const transform = (node: ContentfulTypeProgram): Program => ({
  id: node.id,
  url: get(node, 'url', null),
  name: get(node, 'name', ''),
  description: get(node, 'description.description', ''),
  image: {
    src: get(node, 'image.file.url', imageFallback),
    alt: get(node, 'image.description', 'None provided'),
  },
})
