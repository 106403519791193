import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  CFResponsiveAsset,
  MarkdownRemark,
  ResponsiveImage,
} from './types/gatsby'
import {
  ContentfulTypeProgram,
  Program,
  transform as toProgram,
} from './program'
import {
  ContentfulTypeCompany,
  Company,
  transform as toCompany,
} from './company'
import { LinkItem, ContentfulTypeLink, transform as toLinkItem } from './link'

export type ContentfulPageHome = {
  id: string
  headerSubtitle: string
  headerTitle: string
  headerDescription: MarkdownRemark
  headerButtonEnabled: boolean
  headerButton: ContentfulTypeLink
  headerImages: CFResponsiveAsset[]
  featuredCompaniesTitle: string
  featuredCompaniesTagline: string
  featuredCompanies: ContentfulTypeCompany[]
  programsTitle: string
  programs: ContentfulTypeProgram
  ctaSectionEnabled: boolean
  ctaSectionTitle: string
  ctaSectionButton: ContentfulTypeLink
}
export type HomePage = {
  id: string
  header: {
    title: string
    subtitle: string
    description: string
    images: ResponsiveImage[]
    button: LinkItem | null
  }
  featuredCompanies: {
    title: string
    subtitle: string
    items: Company[]
  }
  programs: {
    title: string
    items: Program[]
  }
  ctaSection: null | {
    title: string
    button: LinkItem
  }
}

export const fragment = graphql`
  fragment HomePageFields on ContentfulPageHome {
    id
    headerTitle
    headerSubtitle
    headerDescription {
      childMarkdownRemark {
        html
      }
    }
    headerButtonEnabled
    headerButton {
      ...LinkFields
    }
    headerImages {
      ...ResponsiveAssetFields
    }
    featuredCompaniesSubtitle
    featuredCompaniesTitle
    featuredCompanies {
      ...CompanyFields
    }
    programsTitle
    programs {
      ...ProgramFields
    }
    ctaSectionEnabled
    ctaSectionTitle
    ctaSectionButton {
      ...LinkFields
    }
  }
`

const toResponsiveImage = (img: CFResponsiveAsset) =>
  !img
    ? null
    : {
        sizes: img.sizes,
        alt: img.title || img.description,
      }

export const transform = (node: ContentfulPageHome): HomePage => ({
  id: node.id,
  header: {
    title: get(node, 'headerTitle', ''),
    subtitle: get(node, 'headerSubtitle', ''),
    description: get(node, 'headerDescription.childMarkdownRemark.html', ''),
    images: get(node, 'headerImages', []).map(img => toResponsiveImage(img)),
    button: get(node, 'headerButtonEnabled', false)
      ? toLinkItem(get(node, 'headerButton'))
      : null,
  },
  featuredCompanies: {
    subtitle: get(node, 'featuredCompaniesSubtitle', ''),
    title: get(node, 'featuredCompaniesTitle', ''),
    items: get(node, 'featuredCompanies', []).map(x => toCompany(x)),
  },
  programs: {
    title: get(node, 'programsTitle', ''),
    items: get(node, 'programs', []).map(x => toProgram(x)),
  },
  ctaSection: get(node, 'ctaSectionEnabled', false)
    ? {
        title: get(node, 'ctaSectionTitle', ''),
        button: toLinkItem(get(node, 'ctaSectionButton')),
      }
    : null,
})
